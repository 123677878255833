@charset "UTF-8";
/*
	Please, compile this file to /css/styles.css (or /css/styles.min.css);
	You can change the variables in the _custom-variables.scss file;
	All additional classes, styles, etc. can be found in the _custom.scss file;
	All other imports are copy-pasted from the bootstrap.scss file. You can comment imports that you don't use to make compiled CSS file weight less.
*/
/*
	Unfortunately, if we want to use some values from the _variables.scss, we should set them here too. 

	Otherwise, we'll catch an error that this variable is undefined. 
	This is caused because this file included in bootstrap.scss BEFORE the _variables.scss - 
	we MUST do that because of all the variables in the _variables.scss file has a !default statement. 
	That means that since this variable was defined it can not be overwritten anymore. 
	That's why we must put our variables before the default Bootstrap variables to overwrite them, 
	instead of simply including this file after the _variables.scss. 

	Weird, right? :)

	For example, we assign these default variables here:
	$white, $teal, $cyan, $font-size-base, $font-weight-bold (maybe some others after writing this comment)
*/
:root {
  --bs-blue: #25dac5;
  --bs-indigo: #2f1893;
  --bs-purple: #2f1893;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #e93a7d;
  --bs-green: #8bd74e;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: rgba(255, 255, 255, 0.7);
  --bs-gray-dark: rgba(39, 40, 42, 0.7);
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-action-1: #25dac5;
  --bs-action-2: #1e0e62;
  --bs-action-3: #2f1893;
  --bs-action-4: #d63384;
  --bs-action-5: #dc3545;
  --bs-action-6: #fd7e14;
  --bs-action-7: #e93a7d;
  --bs-action-8: #8bd74e;
  --bs-dark-1: #1e0e62;
  --bs-dark-2: rgba(39, 40, 42, 0.7);
  --bs-dark-3: rgba(39, 40, 42, 0.3);
  --bs-light-1: #ffffff;
  --bs-light-2: rgba(255, 255, 255, 0.7);
  --bs-light-3: rgba(255, 255, 255, 0.45);
  --bs-bg-1: #272829;
  --bs-bg-2: #2f1893;
  --bs-bg-3: #ffffff;
  --bs-action-1-rgb: 37, 218, 197;
  --bs-action-2-rgb: 30, 14, 98;
  --bs-action-3-rgb: 47, 24, 147;
  --bs-action-4-rgb: 214, 51, 132;
  --bs-action-5-rgb: 220, 53, 69;
  --bs-action-6-rgb: 253, 126, 20;
  --bs-action-7-rgb: 233, 58, 125;
  --bs-action-8-rgb: 139, 215, 78;
  --bs-dark-1-rgb: 30, 14, 98;
  --bs-dark-2-rgb: 39, 40, 42;
  --bs-dark-3-rgb: 39, 40, 42;
  --bs-light-1-rgb: 255, 255, 255;
  --bs-light-2-rgb: 255, 255, 255;
  --bs-light-3-rgb: 255, 255, 255;
  --bs-bg-1-rgb: 39, 40, 41;
  --bs-bg-2-rgb: 47, 24, 147;
  --bs-bg-3-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 30, 14, 98;
  --bs-body-color-rgb: 39, 40, 42;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: 'Baloo 2', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: rgba(39, 40, 42, 0.7);
  --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(30, 14, 98, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Baloo 2';
  font-weight: 700;
  line-height: 1.25;
  color: #1e0e62;
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #25dac5;
  text-decoration: none;
}
a:hover {
  color: #1eae9e;
  text-decoration: none;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 1.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.675rem + 5.1vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5.5rem;
  }
}

.display-2 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 5rem;
  }
}

.display-3 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4.5rem;
  }
}

.display-4 {
  font-size: calc(1.5rem + 3vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.75rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: '— ';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 1.5625rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 2.75rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 2.75rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 3.25rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 3.25rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 3.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 3.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 3.75rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 3.75rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 4rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 4rem;
}

.g-17,
.gx-17 {
  --bs-gutter-x: 4.25rem;
}

.g-17,
.gy-17 {
  --bs-gutter-y: 4.25rem;
}

.g-18,
.gx-18 {
  --bs-gutter-x: 4.5rem;
}

.g-18,
.gy-18 {
  --bs-gutter-y: 4.5rem;
}

.g-19,
.gx-19 {
  --bs-gutter-x: 4.75rem;
}

.g-19,
.gy-19 {
  --bs-gutter-y: 4.75rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 5rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-17,
  .gx-sm-17 {
    --bs-gutter-x: 4.25rem;
  }

  .g-sm-17,
  .gy-sm-17 {
    --bs-gutter-y: 4.25rem;
  }

  .g-sm-18,
  .gx-sm-18 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-18,
  .gy-sm-18 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-19,
  .gx-sm-19 {
    --bs-gutter-x: 4.75rem;
  }

  .g-sm-19,
  .gy-sm-19 {
    --bs-gutter-y: 4.75rem;
  }

  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 3rem;
  }

  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 3rem;
  }

  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 4rem;
  }

  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 4rem;
  }

  .g-md-17,
  .gx-md-17 {
    --bs-gutter-x: 4.25rem;
  }

  .g-md-17,
  .gy-md-17 {
    --bs-gutter-y: 4.25rem;
  }

  .g-md-18,
  .gx-md-18 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-18,
  .gy-md-18 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-19,
  .gx-md-19 {
    --bs-gutter-x: 4.75rem;
  }

  .g-md-19,
  .gy-md-19 {
    --bs-gutter-y: 4.75rem;
  }

  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 5rem;
  }

  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-17,
  .gx-lg-17 {
    --bs-gutter-x: 4.25rem;
  }

  .g-lg-17,
  .gy-lg-17 {
    --bs-gutter-y: 4.25rem;
  }

  .g-lg-18,
  .gx-lg-18 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-18,
  .gy-lg-18 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-19,
  .gx-lg-19 {
    --bs-gutter-x: 4.75rem;
  }

  .g-lg-19,
  .gy-lg-19 {
    --bs-gutter-y: 4.75rem;
  }

  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-17,
  .gx-xl-17 {
    --bs-gutter-x: 4.25rem;
  }

  .g-xl-17,
  .gy-xl-17 {
    --bs-gutter-y: 4.25rem;
  }

  .g-xl-18,
  .gx-xl-18 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-18,
  .gy-xl-18 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-19,
  .gx-xl-19 {
    --bs-gutter-x: 4.75rem;
  }

  .g-xl-19,
  .gy-xl-19 {
    --bs-gutter-y: 4.75rem;
  }

  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-17,
  .gx-xxl-17 {
    --bs-gutter-x: 4.25rem;
  }

  .g-xxl-17,
  .gy-xxl-17 {
    --bs-gutter-y: 4.25rem;
  }

  .g-xxl-18,
  .gx-xxl-18 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-18,
  .gy-xxl-18 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-19,
  .gx-xxl-19 {
    --bs-gutter-x: 4.75rem;
  }

  .g-xxl-19,
  .gy-xxl-19 {
    --bs-gutter-y: 4.75rem;
  }

  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: rgba(39, 40, 42, 0.7);
  --bs-table-striped-bg: rgba(30, 14, 98, 0.05);
  --bs-table-active-color: rgba(39, 40, 42, 0.7);
  --bs-table-active-bg: rgba(30, 14, 98, 0.1);
  --bs-table-hover-color: rgba(39, 40, 42, 0.7);
  --bs-table-hover-bg: rgba(30, 14, 98, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(39, 40, 42, 0.7);
  vertical-align: top;
  border-color: rgba(39, 40, 42, 0.3);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d3f8f3;
  --bs-table-striped-bg: #caecec;
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: #c1e1e5;
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: #c5e6e8;
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: #c1e1e5;
}

.table-secondary {
  --bs-table-bg: rgba(229, 229, 230, 0.94);
  --bs-table-striped-bg: rgba(218, 217, 223, 0.943);
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: rgba(207, 205, 215, 0.946);
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: rgba(212, 211, 219, 0.9445);
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: rgba(207, 205, 215, 0.946);
}

.table-success {
  --bs-table-bg: #e8f7dc;
  --bs-table-striped-bg: #deebd6;
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: #d4e0d0;
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: #d9e6d3;
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: #d4e0d0;
}

.table-info {
  --bs-table-bg: #d5d1e9;
  --bs-table-striped-bg: #ccc7e2;
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: #c3bedc;
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: #c7c2df;
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: #c3bedc;
}

.table-warning {
  --bs-table-bg: #fbd8e5;
  --bs-table-striped-bg: #f0cede;
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: #e5c4d8;
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: #eac9db;
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: #e5c4d8;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #edcdd4;
  --bs-table-striped-color: #1e0e62;
  --bs-table-active-bg: #e2c3ce;
  --bs-table-active-color: #1e0e62;
  --bs-table-hover-bg: #e8c8d1;
  --bs-table-hover-color: #1e0e62;
  color: #1e0e62;
  border-color: #e2c3ce;
}

.table-light {
  --bs-table-bg: #2f1893;
  --bs-table-striped-bg: #392498;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #442f9e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #3f299b;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #442f9e;
}

.table-dark {
  --bs-table-bg: #272829;
  --bs-table-striped-bg: #323334;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3d3e3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #373839;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #3d3e3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2;
}

.col-form-label-lg {
  padding-top: calc(1.0625rem + 1px);
  padding-bottom: calc(1.0625rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.5875rem + 1px);
  padding-bottom: calc(0.5875rem + 1px);
  font-size: 1rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.825rem 1.1875rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  color: #1e0e62;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(39, 40, 42, 0.3);
  appearance: none;
  border-radius: 1.5625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type='file'] {
  overflow: hidden;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1e0e62;
  background-color: transparent;
  border-color: #92ede2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.2em;
}
.form-control::placeholder {
  color: rgba(39, 40, 42, 0.3);
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(39, 40, 42, 0.3);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.825rem 1.1875rem;
  margin: -0.825rem -1.1875rem;
  margin-inline-end: 1.1875rem;
  color: #1e0e62;
  background-color: transparent;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: rgba(0, 0, 0, 0.05);
}
.form-control::-webkit-file-upload-button {
  padding: 0.825rem 1.1875rem;
  margin: -0.825rem -1.1875rem;
  margin-inline-end: 1.1875rem;
  color: #1e0e62;
  background-color: transparent;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(0, 0, 0, 0.05);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.825rem 0;
  margin-bottom: 0;
  line-height: 1.2;
  color: rgba(39, 40, 42, 0.7);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.2em + 1.175rem + 2px);
  padding: 0.5875rem 0.9375rem;
  font-size: 1rem;
  border-radius: 1.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.5875rem 0.9375rem;
  margin: -0.5875rem -0.9375rem;
  margin-inline-end: 0.9375rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.5875rem 0.9375rem;
  margin: -0.5875rem -0.9375rem;
  margin-inline-end: 0.9375rem;
}

.form-control-lg {
  min-height: calc(1.2em + 2.125rem + 2px);
  padding: 1.0625rem 1.4375rem;
  font-size: 1.25rem;
  border-radius: 1.875rem;
}
.form-control-lg::file-selector-button {
  padding: 1.0625rem 1.4375rem;
  margin: -1.0625rem -1.4375rem;
  margin-inline-end: 1.4375rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1.0625rem 1.4375rem;
  margin: -1.0625rem -1.4375rem;
  margin-inline-end: 1.4375rem;
}

textarea.form-control {
  min-height: calc(1.2em + 1.65rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.2em + 1.175rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.2em + 2.125rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.825rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.2em;
  border-radius: 1.5625rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.2em;
  border-radius: 1.5625rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.825rem 3.5625rem 0.825rem 1.1875rem;
  -moz-padding-start: calc(1.1875rem - 3px);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  color: #1e0e62;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.1875rem center;
  background-size: 16px 12px;
  border: 1px solid rgba(39, 40, 42, 0.3);
  border-radius: 1.5625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #92ede2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 1.1875rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e0e62;
}

.form-select-sm {
  padding-top: 0.5875rem;
  padding-bottom: 0.5875rem;
  padding-left: 0.9375rem;
  font-size: 1rem;
  border-radius: 1.25rem;
}

.form-select-lg {
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem;
  padding-left: 1.4375rem;
  font-size: 1.25rem;
  border-radius: 1.875rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 0;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0;
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0rem;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(39, 40, 42, 0.3);
  appearance: none;
  color-adjust: exact;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type='checkbox'] {
  border-radius: 1.25rem;
}
.form-check-input[type='radio'] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #25dac5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.form-check-input:checked {
  background-color: #25dac5;
  border-color: #25dac5;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18.7104 7.20986C18.6175 7.11613 18.5069 7.04174 18.385 6.99097C18.2632 6.9402 18.1324 6.91406 18.0004 6.91406C17.8684 6.91406 17.7377 6.9402 17.6159 6.99097C17.494 7.04174 17.3834 7.11613 17.2904 7.20986L9.84044 14.6699L6.71044 11.5299C6.61392 11.4366 6.49998 11.3633 6.37512 11.3141C6.25026 11.2649 6.11694 11.2408 5.98276 11.2431C5.84858 11.2454 5.71617 11.2741 5.59309 11.3276C5.47001 11.3811 5.35868 11.4583 5.26544 11.5549C5.1722 11.6514 5.09889 11.7653 5.04968 11.8902C5.00048 12.015 4.97635 12.1484 4.97867 12.2825C4.98099 12.4167 5.00972 12.5491 5.06321 12.6722C5.1167 12.7953 5.19392 12.9066 5.29044 12.9999L9.13044 16.8399C9.2234 16.9336 9.334 17.008 9.45586 17.0588C9.57772 17.1095 9.70843 17.1357 9.84044 17.1357C9.97245 17.1357 10.1032 17.1095 10.225 17.0588C10.3469 17.008 10.4575 16.9336 10.5504 16.8399L18.7104 8.67986C18.8119 8.58622 18.893 8.47257 18.9484 8.34607C19.0038 8.21957 19.0324 8.08296 19.0324 7.94486C19.0324 7.80676 19.0038 7.67015 18.9484 7.54365C18.893 7.41715 18.8119 7.3035 18.7104 7.20986Z' fill='white'/%3e%3c/svg%3e");
}
.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type='checkbox']:indeterminate {
  background-color: #25dac5;
  border-color: #25dac5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-check-label {
  cursor: pointer;
}

.form-switch {
  padding-left: 0;
}
.form-switch .form-check-input {
  width: 2rem;
  margin-left: 0;
  background-image: none;
  background-position: left center;
  border-radius: 2rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2325dac5'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: none;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #25dac5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bef4ee;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #25dac5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bef4ee;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.1875rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.1875rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.825rem 1.1875rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  color: #1e0e62;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid rgba(39, 40, 42, 0.3);
  border-radius: 1.5625rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.0625rem 1.4375rem;
  font-size: 1.25rem;
  border-radius: 1.875rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5875rem 0.9375rem;
  font-size: 1rem;
  border-radius: 1.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4.75rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #8bd74e;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #1e0e62;
  background-color: rgba(139, 215, 78, 0.9);
  border-radius: 1.5625rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #8bd74e;
  padding-right: calc(1.2em + 1.65rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%238BD74E' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.4125rem) center;
  background-size: calc(0.6em + 0.825rem) calc(0.6em + 0.825rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #8bd74e;
  box-shadow: 0 0 0 0 rgba(139, 215, 78, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.2em + 1.65rem);
  background-position: top calc(0.3em + 0.4125rem) right calc(0.3em + 0.4125rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #8bd74e;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  padding-right: 6.53125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%238BD74E' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.1875rem center, center right 3.5625rem;
  background-size: 16px 12px, calc(0.6em + 0.825rem) calc(0.6em + 0.825rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #8bd74e;
  box-shadow: 0 0 0 0 rgba(139, 215, 78, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #8bd74e;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #8bd74e;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(139, 215, 78, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #8bd74e;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 1.5625rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.2em + 1.65rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.4125rem) center;
  background-size: calc(0.6em + 0.825rem) calc(0.6em + 0.825rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.2em + 1.65rem);
  background-position: top calc(0.3em + 0.4125rem) right calc(0.3em + 0.4125rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  padding-right: 6.53125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.1875rem center, center right 3.5625rem;
  background-size: 16px 12px, calc(0.6em + 0.825rem) calc(0.6em + 0.825rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  align-self: center;
  font-weight: 700;
  line-height: 1.2;
  color: rgba(39, 40, 42, 0.7);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.825rem 1.1875rem;
  font-size: 1.125rem;
  border-radius: 1.5625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: rgba(39, 40, 42, 0.7);
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-action-1 {
  color: #1e0e62;
  background-color: #25dac5;
  border-color: #25dac5;
}
.btn-action-1:hover {
  color: #1e0e62;
  background-color: #46e0ce;
  border-color: #3bdecb;
}
.btn-check:focus + .btn-action-1,
.btn-action-1:focus {
  color: #1e0e62;
  background-color: #46e0ce;
  border-color: #3bdecb;
  box-shadow: 0 0 0 0 rgba(36, 187, 182, 0.5);
}
.btn-check:checked + .btn-action-1,
.btn-check:active + .btn-action-1,
.btn-action-1:active,
.btn-action-1.active,
.show > .btn-action-1.dropdown-toggle {
  color: #1e0e62;
  background-color: #51e1d1;
  border-color: #3bdecb;
}
.btn-check:checked + .btn-action-1:focus,
.btn-check:active + .btn-action-1:focus,
.btn-action-1:active:focus,
.btn-action-1.active:focus,
.show > .btn-action-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(36, 187, 182, 0.5);
}
.btn-action-1:disabled,
.btn-action-1.disabled {
  color: #1e0e62;
  background-color: #25dac5;
  border-color: #25dac5;
}

.btn-action-2 {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-action-2:hover {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
}
.btn-check:focus + .btn-action-2,
.btn-action-2:focus {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-check:checked + .btn-action-2,
.btn-check:active + .btn-action-2,
.btn-action-2:active,
.btn-action-2.active,
.show > .btn-action-2.dropdown-toggle {
  color: #ffffff;
  background-color: #261376;
  border-color: #23126e;
}
.btn-check:checked + .btn-action-2:focus,
.btn-check:active + .btn-action-2:focus,
.btn-action-2:active:focus,
.btn-action-2.active:focus,
.show > .btn-action-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-action-2:disabled,
.btn-action-2.disabled {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}

.btn-action-3 {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-action-3:hover {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
}
.btn-check:focus + .btn-action-3,
.btn-action-3:focus {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-check:checked + .btn-action-3,
.btn-check:active + .btn-action-3,
.btn-action-3:active,
.btn-action-3.active,
.show > .btn-action-3.dropdown-toggle {
  color: #ffffff;
  background-color: #261376;
  border-color: #23126e;
}
.btn-check:checked + .btn-action-3:focus,
.btn-check:active + .btn-action-3:focus,
.btn-action-3:active:focus,
.btn-action-3.active:focus,
.show > .btn-action-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-action-3:disabled,
.btn-action-3.disabled {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}

.btn-action-4 {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-action-4:hover {
  color: #ffffff;
  background-color: #b62b70;
  border-color: #ab296a;
}
.btn-check:focus + .btn-action-4,
.btn-action-4:focus {
  color: #ffffff;
  background-color: #b62b70;
  border-color: #ab296a;
  box-shadow: 0 0 0 0 rgba(220, 82, 150, 0.5);
}
.btn-check:checked + .btn-action-4,
.btn-check:active + .btn-action-4,
.btn-action-4:active,
.btn-action-4.active,
.show > .btn-action-4.dropdown-toggle {
  color: #ffffff;
  background-color: #ab296a;
  border-color: #a12663;
}
.btn-check:checked + .btn-action-4:focus,
.btn-check:active + .btn-action-4:focus,
.btn-action-4:active:focus,
.btn-action-4.active:focus,
.show > .btn-action-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 82, 150, 0.5);
}
.btn-action-4:disabled,
.btn-action-4.disabled {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}

.btn-action-5 {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-action-5:hover {
  color: #ffffff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-action-5,
.btn-action-5:focus {
  color: #ffffff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-action-5,
.btn-check:active + .btn-action-5,
.btn-action-5:active,
.btn-action-5.active,
.show > .btn-action-5.dropdown-toggle {
  color: #ffffff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-action-5:focus,
.btn-check:active + .btn-action-5:focus,
.btn-action-5:active:focus,
.btn-action-5.active:focus,
.show > .btn-action-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}
.btn-action-5:disabled,
.btn-action-5.disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-action-6 {
  color: #1e0e62;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-action-6:hover {
  color: #1e0e62;
  background-color: #fd9137;
  border-color: #fd8b2c;
}
.btn-check:focus + .btn-action-6,
.btn-action-6:focus {
  color: #1e0e62;
  background-color: #fd9137;
  border-color: #fd8b2c;
  box-shadow: 0 0 0 0 rgba(220, 109, 32, 0.5);
}
.btn-check:checked + .btn-action-6,
.btn-check:active + .btn-action-6,
.btn-action-6:active,
.btn-action-6.active,
.show > .btn-action-6.dropdown-toggle {
  color: #1e0e62;
  background-color: #fd9843;
  border-color: #fd8b2c;
}
.btn-check:checked + .btn-action-6:focus,
.btn-check:active + .btn-action-6:focus,
.btn-action-6:active:focus,
.btn-action-6.active:focus,
.show > .btn-action-6.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 109, 32, 0.5);
}
.btn-action-6:disabled,
.btn-action-6.disabled {
  color: #1e0e62;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-action-7 {
  color: #ffffff;
  background-color: #e93a7d;
  border-color: #e93a7d;
}
.btn-action-7:hover {
  color: #ffffff;
  background-color: #c6316a;
  border-color: #ba2e64;
}
.btn-check:focus + .btn-action-7,
.btn-action-7:focus {
  color: #ffffff;
  background-color: #c6316a;
  border-color: #ba2e64;
  box-shadow: 0 0 0 0 rgba(236, 88, 145, 0.5);
}
.btn-check:checked + .btn-action-7,
.btn-check:active + .btn-action-7,
.btn-action-7:active,
.btn-action-7.active,
.show > .btn-action-7.dropdown-toggle {
  color: #ffffff;
  background-color: #ba2e64;
  border-color: #af2c5e;
}
.btn-check:checked + .btn-action-7:focus,
.btn-check:active + .btn-action-7:focus,
.btn-action-7:active:focus,
.btn-action-7.active:focus,
.show > .btn-action-7.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(236, 88, 145, 0.5);
}
.btn-action-7:disabled,
.btn-action-7.disabled {
  color: #ffffff;
  background-color: #e93a7d;
  border-color: #e93a7d;
}

.btn-action-8 {
  color: #1e0e62;
  background-color: #8bd74e;
  border-color: #8bd74e;
}
.btn-action-8:hover {
  color: #1e0e62;
  background-color: #9cdd69;
  border-color: #97db60;
}
.btn-check:focus + .btn-action-8,
.btn-action-8:focus {
  color: #1e0e62;
  background-color: #9cdd69;
  border-color: #97db60;
  box-shadow: 0 0 0 0 rgba(123, 185, 81, 0.5);
}
.btn-check:checked + .btn-action-8,
.btn-check:active + .btn-action-8,
.btn-action-8:active,
.btn-action-8.active,
.show > .btn-action-8.dropdown-toggle {
  color: #1e0e62;
  background-color: #a2df71;
  border-color: #97db60;
}
.btn-check:checked + .btn-action-8:focus,
.btn-check:active + .btn-action-8:focus,
.btn-action-8:active:focus,
.btn-action-8.active:focus,
.show > .btn-action-8.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(123, 185, 81, 0.5);
}
.btn-action-8:disabled,
.btn-action-8.disabled {
  color: #1e0e62;
  background-color: #8bd74e;
  border-color: #8bd74e;
}

.btn-dark-1 {
  color: #ffffff;
  background-color: #1e0e62;
  border-color: #1e0e62;
}
.btn-dark-1:hover {
  color: #ffffff;
  background-color: #1a0c53;
  border-color: #180b4e;
}
.btn-check:focus + .btn-dark-1,
.btn-dark-1:focus {
  color: #ffffff;
  background-color: #1a0c53;
  border-color: #180b4e;
  box-shadow: 0 0 0 0 rgba(64, 50, 122, 0.5);
}
.btn-check:checked + .btn-dark-1,
.btn-check:active + .btn-dark-1,
.btn-dark-1:active,
.btn-dark-1.active,
.show > .btn-dark-1.dropdown-toggle {
  color: #ffffff;
  background-color: #180b4e;
  border-color: #170b4a;
}
.btn-check:checked + .btn-dark-1:focus,
.btn-check:active + .btn-dark-1:focus,
.btn-dark-1:active:focus,
.btn-dark-1.active:focus,
.show > .btn-dark-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(64, 50, 122, 0.5);
}
.btn-dark-1:disabled,
.btn-dark-1.disabled {
  color: #ffffff;
  background-color: #1e0e62;
  border-color: #1e0e62;
}

.btn-dark-2 {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}
.btn-dark-2:hover {
  color: #ffffff;
  background-color: rgba(29, 30, 32, 0.745);
  border-color: rgba(27, 27, 29, 0.76);
}
.btn-check:focus + .btn-dark-2,
.btn-dark-2:focus {
  color: #ffffff;
  background-color: rgba(29, 30, 32, 0.745);
  border-color: rgba(27, 27, 29, 0.76);
  box-shadow: 0 0 0 0 rgba(92, 93, 95, 0.5);
}
.btn-check:checked + .btn-dark-2,
.btn-check:active + .btn-dark-2,
.btn-dark-2:active,
.btn-dark-2.active,
.show > .btn-dark-2.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(27, 27, 29, 0.76);
  border-color: rgba(24, 25, 26, 0.775);
}
.btn-check:checked + .btn-dark-2:focus,
.btn-check:active + .btn-dark-2:focus,
.btn-dark-2:active:focus,
.btn-dark-2.active:focus,
.show > .btn-dark-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(92, 93, 95, 0.5);
}
.btn-dark-2:disabled,
.btn-dark-2.disabled {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}

.btn-dark-3 {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}
.btn-dark-3:hover {
  color: #ffffff;
  background-color: rgba(20, 20, 21, 0.405);
  border-color: rgba(16, 17, 17, 0.44);
}
.btn-check:focus + .btn-dark-3,
.btn-dark-3:focus {
  color: #ffffff;
  background-color: rgba(20, 20, 21, 0.405);
  border-color: rgba(16, 17, 17, 0.44);
  box-shadow: 0 0 0 0 rgba(147, 148, 149, 0.5);
}
.btn-check:checked + .btn-dark-3,
.btn-check:active + .btn-dark-3,
.btn-dark-3:active,
.btn-dark-3.active,
.show > .btn-dark-3.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(16, 17, 17, 0.44);
  border-color: rgba(14, 14, 15, 0.475);
}
.btn-check:checked + .btn-dark-3:focus,
.btn-check:active + .btn-dark-3:focus,
.btn-dark-3:active:focus,
.btn-dark-3.active:focus,
.show > .btn-dark-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(147, 148, 149, 0.5);
}
.btn-dark-3:disabled,
.btn-dark-3.disabled {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}

.btn-light-1 {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light-1:hover {
  color: #1e0e62;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-light-1,
.btn-light-1:focus {
  color: #1e0e62;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0 rgba(221, 219, 231, 0.5);
}
.btn-check:checked + .btn-light-1,
.btn-check:active + .btn-light-1,
.btn-light-1:active,
.btn-light-1.active,
.show > .btn-light-1.dropdown-toggle {
  color: #1e0e62;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-light-1:focus,
.btn-check:active + .btn-light-1:focus,
.btn-light-1:active:focus,
.btn-light-1.active:focus,
.show > .btn-light-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 219, 231, 0.5);
}
.btn-light-1:disabled,
.btn-light-1.disabled {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-light-2 {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-light-2:hover {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.745);
  border-color: rgba(255, 255, 255, 0.73);
}
.btn-check:focus + .btn-light-2,
.btn-light-2:focus {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.745);
  border-color: rgba(255, 255, 255, 0.73);
  box-shadow: 0 0 0 0 rgba(199, 196, 216, 0.5);
}
.btn-check:checked + .btn-light-2,
.btn-check:active + .btn-light-2,
.btn-light-2:active,
.btn-light-2.active,
.show > .btn-light-2.dropdown-toggle {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.76);
  border-color: rgba(255, 255, 255, 0.73);
}
.btn-check:checked + .btn-light-2:focus,
.btn-check:active + .btn-light-2:focus,
.btn-light-2:active:focus,
.btn-light-2.active:focus,
.show > .btn-light-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(199, 196, 216, 0.5);
}
.btn-light-2:disabled,
.btn-light-2.disabled {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

.btn-light-3 {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}
.btn-light-3:hover {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.5325);
  border-color: rgba(255, 255, 255, 0.505);
}
.btn-check:focus + .btn-light-3,
.btn-light-3:focus {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.5325);
  border-color: rgba(255, 255, 255, 0.505);
  box-shadow: 0 0 0 0 rgba(170, 164, 196, 0.5);
}
.btn-check:checked + .btn-light-3,
.btn-check:active + .btn-light-3,
.btn-light-3:active,
.btn-light-3.active,
.show > .btn-light-3.dropdown-toggle {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.56);
  border-color: rgba(255, 255, 255, 0.505);
}
.btn-check:checked + .btn-light-3:focus,
.btn-check:active + .btn-light-3:focus,
.btn-light-3:active:focus,
.btn-light-3.active:focus,
.show > .btn-light-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(170, 164, 196, 0.5);
}
.btn-light-3:disabled,
.btn-light-3.disabled {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}

.btn-bg-1 {
  color: #ffffff;
  background-color: #272829;
  border-color: #272829;
}
.btn-bg-1:hover {
  color: #ffffff;
  background-color: #212223;
  border-color: #1f2021;
}
.btn-check:focus + .btn-bg-1,
.btn-bg-1:focus {
  color: #ffffff;
  background-color: #212223;
  border-color: #1f2021;
  box-shadow: 0 0 0 0 rgba(71, 72, 73, 0.5);
}
.btn-check:checked + .btn-bg-1,
.btn-check:active + .btn-bg-1,
.btn-bg-1:active,
.btn-bg-1.active,
.show > .btn-bg-1.dropdown-toggle {
  color: #ffffff;
  background-color: #1f2021;
  border-color: #1d1e1f;
}
.btn-check:checked + .btn-bg-1:focus,
.btn-check:active + .btn-bg-1:focus,
.btn-bg-1:active:focus,
.btn-bg-1.active:focus,
.show > .btn-bg-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(71, 72, 73, 0.5);
}
.btn-bg-1:disabled,
.btn-bg-1.disabled {
  color: #ffffff;
  background-color: #272829;
  border-color: #272829;
}

.btn-bg-2 {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-bg-2:hover {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
}
.btn-check:focus + .btn-bg-2,
.btn-bg-2:focus {
  color: #ffffff;
  background-color: #28147d;
  border-color: #261376;
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-check:checked + .btn-bg-2,
.btn-check:active + .btn-bg-2,
.btn-bg-2:active,
.btn-bg-2.active,
.show > .btn-bg-2.dropdown-toggle {
  color: #ffffff;
  background-color: #261376;
  border-color: #23126e;
}
.btn-check:checked + .btn-bg-2:focus,
.btn-check:active + .btn-bg-2:focus,
.btn-bg-2:active:focus,
.btn-bg-2.active:focus,
.show > .btn-bg-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(78, 59, 163, 0.5);
}
.btn-bg-2:disabled,
.btn-bg-2.disabled {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}

.btn-bg-3 {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-bg-3:hover {
  color: #1e0e62;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-bg-3,
.btn-bg-3:focus {
  color: #1e0e62;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0 rgba(221, 219, 231, 0.5);
}
.btn-check:checked + .btn-bg-3,
.btn-check:active + .btn-bg-3,
.btn-bg-3:active,
.btn-bg-3.active,
.show > .btn-bg-3.dropdown-toggle {
  color: #1e0e62;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-bg-3:focus,
.btn-check:active + .btn-bg-3:focus,
.btn-bg-3:active:focus,
.btn-bg-3.active:focus,
.show > .btn-bg-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 219, 231, 0.5);
}
.btn-bg-3:disabled,
.btn-bg-3.disabled {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-action-1 {
  color: #25dac5 !important ;
  border-color: #25dac5 !important;
}
.btn-outline-action-1:hover {
  color: #1e0e62 !important;
  background-color: #25dac5 !important ;
  border-color: #25dac5 !important;
}
.btn-check:focus + .btn-outline-action-1,
.btn-outline-action-1:focus {
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.5);
}
.btn-check:checked + .btn-outline-action-1,
.btn-check:active + .btn-outline-action-1,
.btn-outline-action-1:active,
.btn-outline-action-1.active,
.btn-outline-action-1.dropdown-toggle.show {
  color: #1e0e62;
  background-color: #25dac5;
  border-color: #25dac5;
}
.btn-check:checked + .btn-outline-action-1:focus,
.btn-check:active + .btn-outline-action-1:focus,
.btn-outline-action-1:active:focus,
.btn-outline-action-1.active:focus,
.btn-outline-action-1.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.5);
}
.btn-outline-action-1:disabled,
.btn-outline-action-1.disabled {
  color: #25dac5;
  background-color: transparent;
}

.btn-outline-action-2 {
  color: #2f1893;
  border-color: #2f1893;
}
.btn-outline-action-2:hover {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:focus + .btn-outline-action-2,
.btn-outline-action-2:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-check:checked + .btn-outline-action-2,
.btn-check:active + .btn-outline-action-2,
.btn-outline-action-2:active,
.btn-outline-action-2.active,
.btn-outline-action-2.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:checked + .btn-outline-action-2:focus,
.btn-check:active + .btn-outline-action-2:focus,
.btn-outline-action-2:active:focus,
.btn-outline-action-2.active:focus,
.btn-outline-action-2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-outline-action-2:disabled,
.btn-outline-action-2.disabled {
  color: #2f1893;
  background-color: transparent;
}

.btn-outline-action-3 {
  color: #2f1893;
  border-color: #2f1893;
}
.btn-outline-action-3:hover {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:focus + .btn-outline-action-3,
.btn-outline-action-3:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-check:checked + .btn-outline-action-3,
.btn-check:active + .btn-outline-action-3,
.btn-outline-action-3:active,
.btn-outline-action-3.active,
.btn-outline-action-3.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:checked + .btn-outline-action-3:focus,
.btn-check:active + .btn-outline-action-3:focus,
.btn-outline-action-3:active:focus,
.btn-outline-action-3.active:focus,
.btn-outline-action-3.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-outline-action-3:disabled,
.btn-outline-action-3.disabled {
  color: #2f1893;
  background-color: transparent;
}

.btn-outline-action-4 {
  color: #d63384;
  border-color: #d63384;
}
.btn-outline-action-4:hover {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:focus + .btn-outline-action-4,
.btn-outline-action-4:focus {
  box-shadow: 0 0 0 0 rgba(214, 51, 132, 0.5);
}
.btn-check:checked + .btn-outline-action-4,
.btn-check:active + .btn-outline-action-4,
.btn-outline-action-4:active,
.btn-outline-action-4.active,
.btn-outline-action-4.dropdown-toggle.show {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:checked + .btn-outline-action-4:focus,
.btn-check:active + .btn-outline-action-4:focus,
.btn-outline-action-4:active:focus,
.btn-outline-action-4.active:focus,
.btn-outline-action-4.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(214, 51, 132, 0.5);
}
.btn-outline-action-4:disabled,
.btn-outline-action-4.disabled {
  color: #d63384;
  background-color: transparent;
}

.btn-outline-action-5 {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-action-5:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-action-5,
.btn-outline-action-5:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-action-5,
.btn-check:active + .btn-outline-action-5,
.btn-outline-action-5:active,
.btn-outline-action-5.active,
.btn-outline-action-5.dropdown-toggle.show {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-action-5:focus,
.btn-check:active + .btn-outline-action-5:focus,
.btn-outline-action-5:active:focus,
.btn-outline-action-5.active:focus,
.btn-outline-action-5.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}
.btn-outline-action-5:disabled,
.btn-outline-action-5.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-action-6 {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-action-6:hover {
  color: #1e0e62;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:focus + .btn-outline-action-6,
.btn-outline-action-6:focus {
  box-shadow: 0 0 0 0 rgba(253, 126, 20, 0.5);
}
.btn-check:checked + .btn-outline-action-6,
.btn-check:active + .btn-outline-action-6,
.btn-outline-action-6:active,
.btn-outline-action-6.active,
.btn-outline-action-6.dropdown-toggle.show {
  color: #1e0e62;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:checked + .btn-outline-action-6:focus,
.btn-check:active + .btn-outline-action-6:focus,
.btn-outline-action-6:active:focus,
.btn-outline-action-6.active:focus,
.btn-outline-action-6.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(253, 126, 20, 0.5);
}
.btn-outline-action-6:disabled,
.btn-outline-action-6.disabled {
  color: #fd7e14;
  background-color: transparent;
}

.btn-outline-action-7 {
  color: #e93a7d;
  border-color: #e93a7d;
}
.btn-outline-action-7:hover {
  color: #ffffff;
  background-color: #e93a7d;
  border-color: #e93a7d;
}
.btn-check:focus + .btn-outline-action-7,
.btn-outline-action-7:focus {
  box-shadow: 0 0 0 0 rgba(233, 58, 125, 0.5);
}
.btn-check:checked + .btn-outline-action-7,
.btn-check:active + .btn-outline-action-7,
.btn-outline-action-7:active,
.btn-outline-action-7.active,
.btn-outline-action-7.dropdown-toggle.show {
  color: #ffffff;
  background-color: #e93a7d;
  border-color: #e93a7d;
}
.btn-check:checked + .btn-outline-action-7:focus,
.btn-check:active + .btn-outline-action-7:focus,
.btn-outline-action-7:active:focus,
.btn-outline-action-7.active:focus,
.btn-outline-action-7.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(233, 58, 125, 0.5);
}
.btn-outline-action-7:disabled,
.btn-outline-action-7.disabled {
  color: #e93a7d;
  background-color: transparent;
}

.btn-outline-action-8 {
  color: #8bd74e;
  border-color: #8bd74e;
}
.btn-outline-action-8:hover {
  color: #1e0e62;
  background-color: #8bd74e;
  border-color: #8bd74e;
}
.btn-check:focus + .btn-outline-action-8,
.btn-outline-action-8:focus {
  box-shadow: 0 0 0 0 rgba(139, 215, 78, 0.5);
}
.btn-check:checked + .btn-outline-action-8,
.btn-check:active + .btn-outline-action-8,
.btn-outline-action-8:active,
.btn-outline-action-8.active,
.btn-outline-action-8.dropdown-toggle.show {
  color: #1e0e62;
  background-color: #8bd74e;
  border-color: #8bd74e;
}
.btn-check:checked + .btn-outline-action-8:focus,
.btn-check:active + .btn-outline-action-8:focus,
.btn-outline-action-8:active:focus,
.btn-outline-action-8.active:focus,
.btn-outline-action-8.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(139, 215, 78, 0.5);
}
.btn-outline-action-8:disabled,
.btn-outline-action-8.disabled {
  color: #8bd74e;
  background-color: transparent;
}

.btn-outline-dark-1 {
  color: #1e0e62;
  border-color: #1e0e62;
}
.btn-outline-dark-1:hover {
  color: #ffffff;
  background-color: #1e0e62;
  border-color: #1e0e62;
}
.btn-check:focus + .btn-outline-dark-1,
.btn-outline-dark-1:focus {
  box-shadow: 0 0 0 0 rgba(30, 14, 98, 0.5);
}
.btn-check:checked + .btn-outline-dark-1,
.btn-check:active + .btn-outline-dark-1,
.btn-outline-dark-1:active,
.btn-outline-dark-1.active,
.btn-outline-dark-1.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1e0e62;
  border-color: #1e0e62;
}
.btn-check:checked + .btn-outline-dark-1:focus,
.btn-check:active + .btn-outline-dark-1:focus,
.btn-outline-dark-1:active:focus,
.btn-outline-dark-1.active:focus,
.btn-outline-dark-1.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(30, 14, 98, 0.5);
}
.btn-outline-dark-1:disabled,
.btn-outline-dark-1.disabled {
  color: #1e0e62;
  background-color: transparent;
}

.btn-outline-dark-2 {
  color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}
.btn-outline-dark-2:hover {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}
.btn-check:focus + .btn-outline-dark-2,
.btn-outline-dark-2:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 42, 0.5);
}
.btn-check:checked + .btn-outline-dark-2,
.btn-check:active + .btn-outline-dark-2,
.btn-outline-dark-2:active,
.btn-outline-dark-2.active,
.btn-outline-dark-2.dropdown-toggle.show {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}
.btn-check:checked + .btn-outline-dark-2:focus,
.btn-check:active + .btn-outline-dark-2:focus,
.btn-outline-dark-2:active:focus,
.btn-outline-dark-2.active:focus,
.btn-outline-dark-2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 42, 0.5);
}
.btn-outline-dark-2:disabled,
.btn-outline-dark-2.disabled {
  color: rgba(39, 40, 42, 0.7);
  background-color: transparent;
}

.btn-outline-dark-3 {
  color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}
.btn-outline-dark-3:hover {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}
.btn-check:focus + .btn-outline-dark-3,
.btn-outline-dark-3:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 42, 0.5);
}
.btn-check:checked + .btn-outline-dark-3,
.btn-check:active + .btn-outline-dark-3,
.btn-outline-dark-3:active,
.btn-outline-dark-3.active,
.btn-outline-dark-3.dropdown-toggle.show {
  color: #ffffff;
  background-color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}
.btn-check:checked + .btn-outline-dark-3:focus,
.btn-check:active + .btn-outline-dark-3:focus,
.btn-outline-dark-3:active:focus,
.btn-outline-dark-3.active:focus,
.btn-outline-dark-3.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 42, 0.5);
}
.btn-outline-dark-3:disabled,
.btn-outline-dark-3.disabled {
  color: rgba(39, 40, 42, 0.3);
  background-color: transparent;
}

.btn-outline-light-1 {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light-1:hover {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-light-1,
.btn-outline-light-1:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-light-1,
.btn-check:active + .btn-outline-light-1,
.btn-outline-light-1:active,
.btn-outline-light-1.active,
.btn-outline-light-1.dropdown-toggle.show {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-light-1:focus,
.btn-check:active + .btn-outline-light-1:focus,
.btn-outline-light-1:active:focus,
.btn-outline-light-1.active:focus,
.btn-outline-light-1.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-light-1:disabled,
.btn-outline-light-1.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-light-2 {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-outline-light-2:hover {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-check:focus + .btn-outline-light-2,
.btn-outline-light-2:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-light-2,
.btn-check:active + .btn-outline-light-2,
.btn-outline-light-2:active,
.btn-outline-light-2.active,
.btn-outline-light-2.dropdown-toggle.show {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-check:checked + .btn-outline-light-2:focus,
.btn-check:active + .btn-outline-light-2:focus,
.btn-outline-light-2:active:focus,
.btn-outline-light-2.active:focus,
.btn-outline-light-2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-light-2:disabled,
.btn-outline-light-2.disabled {
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent;
}

.btn-outline-light-3 {
  color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}
.btn-outline-light-3:hover {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}
.btn-check:focus + .btn-outline-light-3,
.btn-outline-light-3:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-light-3,
.btn-check:active + .btn-outline-light-3,
.btn-outline-light-3:active,
.btn-outline-light-3.active,
.btn-outline-light-3.dropdown-toggle.show {
  color: #1e0e62;
  background-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}
.btn-check:checked + .btn-outline-light-3:focus,
.btn-check:active + .btn-outline-light-3:focus,
.btn-outline-light-3:active:focus,
.btn-outline-light-3.active:focus,
.btn-outline-light-3.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-light-3:disabled,
.btn-outline-light-3.disabled {
  color: rgba(255, 255, 255, 0.45);
  background-color: transparent;
}

.btn-outline-bg-1 {
  color: #272829;
  border-color: #272829;
}
.btn-outline-bg-1:hover {
  color: #ffffff;
  background-color: #272829;
  border-color: #272829;
}
.btn-check:focus + .btn-outline-bg-1,
.btn-outline-bg-1:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 41, 0.5);
}
.btn-check:checked + .btn-outline-bg-1,
.btn-check:active + .btn-outline-bg-1,
.btn-outline-bg-1:active,
.btn-outline-bg-1.active,
.btn-outline-bg-1.dropdown-toggle.show {
  color: #ffffff;
  background-color: #272829;
  border-color: #272829;
}
.btn-check:checked + .btn-outline-bg-1:focus,
.btn-check:active + .btn-outline-bg-1:focus,
.btn-outline-bg-1:active:focus,
.btn-outline-bg-1.active:focus,
.btn-outline-bg-1.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(39, 40, 41, 0.5);
}
.btn-outline-bg-1:disabled,
.btn-outline-bg-1.disabled {
  color: #272829;
  background-color: transparent;
}

.btn-outline-bg-2 {
  color: #2f1893;
  border-color: #2f1893;
}
.btn-outline-bg-2:hover {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:focus + .btn-outline-bg-2,
.btn-outline-bg-2:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-check:checked + .btn-outline-bg-2,
.btn-check:active + .btn-outline-bg-2,
.btn-outline-bg-2:active,
.btn-outline-bg-2.active,
.btn-outline-bg-2.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:checked + .btn-outline-bg-2:focus,
.btn-check:active + .btn-outline-bg-2:focus,
.btn-outline-bg-2:active:focus,
.btn-outline-bg-2.active:focus,
.btn-outline-bg-2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-outline-bg-2:disabled,
.btn-outline-bg-2.disabled {
  color: #2f1893;
  background-color: transparent;
}

.btn-outline-bg-3 {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-bg-3:hover {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-bg-3,
.btn-outline-bg-3:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-bg-3,
.btn-check:active + .btn-outline-bg-3,
.btn-outline-bg-3:active,
.btn-outline-bg-3.active,
.btn-outline-bg-3.dropdown-toggle.show {
  color: #1e0e62;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-bg-3:focus,
.btn-check:active + .btn-outline-bg-3:focus,
.btn-outline-bg-3:active:focus,
.btn-outline-bg-3.active:focus,
.btn-outline-bg-3.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-bg-3:disabled,
.btn-outline-bg-3.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #25dac5;
  text-decoration: none;
}
.btn-link:hover {
  color: #1eae9e;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: rgba(255, 255, 255, 0.45);
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 1.0625rem 1.4375rem;
  font-size: 1.25rem;
  border-radius: 1.5625rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5875rem 0.9375rem;
  font-size: 1rem;
  border-radius: 1.5625rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 1rem;
  color: rgba(39, 40, 42, 0.7);
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0px solid rgba(30, 14, 98, 0.15);
  border-radius: 1.5625rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.5rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.5rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(39, 40, 42, 0.3);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1e0e62;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #25dac5;
  background-color: rgba(37, 218, 197, 0.1);
}
.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #25dac5;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: rgba(39, 40, 42, 0.3);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.4625rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #1e0e62;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #1e0e62;
}

.dropdown-menu-dark {
  color: rgba(255, 255, 255, 0.7);
  background-color: #272829;
  border-color: rgba(30, 14, 98, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #ffffff;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #25dac5;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: rgba(255, 255, 255, 0.45);
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #ffffff;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #ffffff;
}
.dropdown-menu-dark .dropdown-header {
  color: #ffffff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.890625rem;
  padding-left: 0.890625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.703125rem;
  padding-left: 0.703125rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.078125rem;
  padding-left: 1.078125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #25dac5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #1eae9e;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 1.5625rem;
  border-top-right-radius: 1.5625rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 1.5625rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #25dac5;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1.5625rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(30, 14, 98, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(30, 14, 98, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(30, 14, 98, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(30, 14, 98, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(30, 14, 98, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(30, 14, 98, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(30, 14, 98, 0.55);
  border-color: rgba(30, 14, 98, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2830, 14, 98, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(30, 14, 98, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(30, 14, 98, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(30, 14, 98, 0.125);
  border-radius: 1.5625rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(1.5625rem - 1px);
  border-top-right-radius: calc(1.5625rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(1.5625rem - 1px);
  border-bottom-left-radius: calc(1.5625rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(30, 14, 98, 0.03);
  border-bottom: 1px solid rgba(30, 14, 98, 0.125);
}
.card-header:first-child {
  border-radius: calc(1.5625rem - 1px) calc(1.5625rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(30, 14, 98, 0.03);
  border-top: 1px solid rgba(30, 14, 98, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(1.5625rem - 1px) calc(1.5625rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(1.5625rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(1.5625rem - 1px);
  border-top-right-radius: calc(1.5625rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(1.5625rem - 1px);
  border-bottom-left-radius: calc(1.5625rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: rgba(39, 40, 42, 0.7);
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #21c4b1;
  background-color: #e9fbf9;
  box-shadow: inset 0 -1px 0 rgba(30, 14, 98, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2321c4b1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%2839, 40, 42, 0.7%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #92ede2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(30, 14, 98, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 1.5625rem;
  border-top-right-radius: 1.5625rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(1.5625rem - 1px);
  border-top-right-radius: calc(1.5625rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 1.5625rem;
  border-bottom-left-radius: 1.5625rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(1.5625rem - 1px);
  border-bottom-left-radius: calc(1.5625rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 1.5625rem;
  border-bottom-left-radius: 1.5625rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, '/')
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #25dac5;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #1eae9e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #1eae9e;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #25dac5;
  border-color: #25dac5;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 1.5625rem;
  border-bottom-left-radius: 1.5625rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 1.5625rem;
  border-bottom-right-radius: 1.5625rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1.5625rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 1.5625rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-action-1 {
  color: #168376;
  background-color: #d3f8f3;
  border-color: #bef4ee;
}
.alert-action-1 .alert-link {
  color: #12695e;
}

.alert-action-2 {
  color: #1c0e58;
  background-color: #d5d1e9;
  border-color: #c1badf;
}
.alert-action-2 .alert-link {
  color: #160b46;
}

.alert-action-3 {
  color: #1c0e58;
  background-color: #d5d1e9;
  border-color: #c1badf;
}
.alert-action-3 .alert-link {
  color: #160b46;
}

.alert-action-4 {
  color: #801f4f;
  background-color: #f7d6e6;
  border-color: #f3c2da;
}
.alert-action-4 .alert-link {
  color: #66193f;
}

.alert-action-5 {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-action-5 .alert-link {
  color: #6a1a21;
}

.alert-action-6 {
  color: #984c0c;
  background-color: #ffe5d0;
  border-color: #fed8b9;
}
.alert-action-6 .alert-link {
  color: #7a3d0a;
}

.alert-action-7 {
  color: #8c234b;
  background-color: #fbd8e5;
  border-color: #f8c4d8;
}
.alert-action-7 .alert-link {
  color: #701c3c;
}

.alert-action-8 {
  color: #53812f;
  background-color: #e8f7dc;
  border-color: #dcf3ca;
}
.alert-action-8 .alert-link {
  color: #426726;
}

.alert-dark-1 {
  color: #12083b;
  background-color: #d2cfe0;
  border-color: #bcb7d0;
}
.alert-dark-1 .alert-link {
  color: #0e062f;
}

.alert-dark-2 {
  color: rgba(17, 18, 19, 0.82);
  background-color: rgba(229, 229, 230, 0.94);
  border-color: rgba(215, 215, 215, 0.91);
}
.alert-dark-2 .alert-link {
  color: rgba(13, 13, 14, 0.856);
}

.alert-dark-3 {
  color: rgba(8, 8, 9, 0.58);
  background-color: rgba(246, 246, 246, 0.86);
  border-color: rgba(240, 240, 240, 0.79);
}
.alert-dark-3 .alert-link {
  color: rgba(5, 5, 6, 0.664);
}

.alert-light-1 {
  color: #786ea1;
  background-color: white;
  border-color: white;
}
.alert-light-1 .alert-link {
  color: #605881;
}

.alert-light-2 {
  color: rgba(114, 114, 114, 0.82);
  background-color: rgba(255, 255, 255, 0.94);
  border-color: rgba(255, 255, 255, 0.91);
}
.alert-light-2 .alert-link {
  color: rgba(84, 84, 84, 0.856);
}

.alert-light-3 {
  color: rgba(77, 77, 77, 0.67);
  background-color: rgba(255, 255, 255, 0.89);
  border-color: rgba(255, 255, 255, 0.835);
}
.alert-light-3 .alert-link {
  color: rgba(51, 51, 51, 0.736);
}

.alert-bg-1 {
  color: #171819;
  background-color: #d4d4d4;
  border-color: #bebfbf;
}
.alert-bg-1 .alert-link {
  color: #121314;
}

.alert-bg-2 {
  color: #1c0e58;
  background-color: #d5d1e9;
  border-color: #c1badf;
}
.alert-bg-2 .alert-link {
  color: #160b46;
}

.alert-bg-3 {
  color: #786ea1;
  background-color: white;
  border-color: white;
}
.alert-bg-3 .alert-link {
  color: #605881;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 1.5625rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #25dac5;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 1.5625rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, '.') '. ';
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: rgba(39, 40, 42, 0.7);
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  background-color: #ffffff;
  border: 1px solid rgba(30, 14, 98, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #25dac5;
  border-color: #25dac5;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 1.5625rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 1.5625rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 1.5625rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 1.5625rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 1.5625rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 1.5625rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 1.5625rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 1.5625rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 1.5625rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 1.5625rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 1.5625rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 1.5625rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-action-1 {
  color: #168376;
  background-color: #d3f8f3;
}
.list-group-item-action-1.list-group-item-action:hover,
.list-group-item-action-1.list-group-item-action:focus {
  color: #168376;
  background-color: #bedfdb;
}
.list-group-item-action-1.list-group-item-action.active {
  color: #ffffff;
  background-color: #168376;
  border-color: #168376;
}

.list-group-item-action-2 {
  color: #1c0e58;
  background-color: #d5d1e9;
}
.list-group-item-action-2.list-group-item-action:hover,
.list-group-item-action-2.list-group-item-action:focus {
  color: #1c0e58;
  background-color: #c0bcd2;
}
.list-group-item-action-2.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c0e58;
  border-color: #1c0e58;
}

.list-group-item-action-3 {
  color: #1c0e58;
  background-color: #d5d1e9;
}
.list-group-item-action-3.list-group-item-action:hover,
.list-group-item-action-3.list-group-item-action:focus {
  color: #1c0e58;
  background-color: #c0bcd2;
}
.list-group-item-action-3.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c0e58;
  border-color: #1c0e58;
}

.list-group-item-action-4 {
  color: #801f4f;
  background-color: #f7d6e6;
}
.list-group-item-action-4.list-group-item-action:hover,
.list-group-item-action-4.list-group-item-action:focus {
  color: #801f4f;
  background-color: #dec1cf;
}
.list-group-item-action-4.list-group-item-action.active {
  color: #ffffff;
  background-color: #801f4f;
  border-color: #801f4f;
}

.list-group-item-action-5 {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-action-5.list-group-item-action:hover,
.list-group-item-action-5.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-action-5.list-group-item-action.active {
  color: #ffffff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-action-6 {
  color: #984c0c;
  background-color: #ffe5d0;
}
.list-group-item-action-6.list-group-item-action:hover,
.list-group-item-action-6.list-group-item-action:focus {
  color: #984c0c;
  background-color: #e6cebb;
}
.list-group-item-action-6.list-group-item-action.active {
  color: #ffffff;
  background-color: #984c0c;
  border-color: #984c0c;
}

.list-group-item-action-7 {
  color: #8c234b;
  background-color: #fbd8e5;
}
.list-group-item-action-7.list-group-item-action:hover,
.list-group-item-action-7.list-group-item-action:focus {
  color: #8c234b;
  background-color: #e2c2ce;
}
.list-group-item-action-7.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c234b;
  border-color: #8c234b;
}

.list-group-item-action-8 {
  color: #53812f;
  background-color: #e8f7dc;
}
.list-group-item-action-8.list-group-item-action:hover,
.list-group-item-action-8.list-group-item-action:focus {
  color: #53812f;
  background-color: #d1dec6;
}
.list-group-item-action-8.list-group-item-action.active {
  color: #ffffff;
  background-color: #53812f;
  border-color: #53812f;
}

.list-group-item-dark-1 {
  color: #12083b;
  background-color: #d2cfe0;
}
.list-group-item-dark-1.list-group-item-action:hover,
.list-group-item-dark-1.list-group-item-action:focus {
  color: #12083b;
  background-color: #bdbaca;
}
.list-group-item-dark-1.list-group-item-action.active {
  color: #ffffff;
  background-color: #12083b;
  border-color: #12083b;
}

.list-group-item-dark-2 {
  color: rgba(17, 18, 19, 0.82);
  background-color: rgba(229, 229, 230, 0.94);
}
.list-group-item-dark-2.list-group-item-action:hover,
.list-group-item-dark-2.list-group-item-action:focus {
  color: rgba(17, 18, 19, 0.82);
  background-color: rgba(204, 204, 204, 0.946);
}
.list-group-item-dark-2.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(17, 18, 19, 0.82);
  border-color: rgba(17, 18, 19, 0.82);
}

.list-group-item-dark-3 {
  color: rgba(8, 8, 9, 0.58);
  background-color: rgba(246, 246, 246, 0.86);
}
.list-group-item-dark-3.list-group-item-action:hover,
.list-group-item-dark-3.list-group-item-action:focus {
  color: rgba(8, 8, 9, 0.58);
  background-color: rgba(214, 214, 214, 0.874);
}
.list-group-item-dark-3.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(8, 8, 9, 0.58);
  border-color: rgba(8, 8, 9, 0.58);
}

.list-group-item-light-1 {
  color: #786ea1;
  background-color: white;
}
.list-group-item-light-1.list-group-item-action:hover,
.list-group-item-light-1.list-group-item-action:focus {
  color: #786ea1;
  background-color: #e6e6e6;
}
.list-group-item-light-1.list-group-item-action.active {
  color: #ffffff;
  background-color: #786ea1;
  border-color: #786ea1;
}

.list-group-item-light-2 {
  color: rgba(114, 114, 114, 0.82);
  background-color: rgba(255, 255, 255, 0.94);
}
.list-group-item-light-2.list-group-item-action:hover,
.list-group-item-light-2.list-group-item-action:focus {
  color: rgba(114, 114, 114, 0.82);
  background-color: rgba(227, 227, 227, 0.946);
}
.list-group-item-light-2.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(114, 114, 114, 0.82);
  border-color: rgba(114, 114, 114, 0.82);
}

.list-group-item-light-3 {
  color: rgba(77, 77, 77, 0.67);
  background-color: rgba(255, 255, 255, 0.89);
}
.list-group-item-light-3.list-group-item-action:hover,
.list-group-item-light-3.list-group-item-action:focus {
  color: rgba(77, 77, 77, 0.67);
  background-color: rgba(224, 224, 224, 0.901);
}
.list-group-item-light-3.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(77, 77, 77, 0.67);
  border-color: rgba(77, 77, 77, 0.67);
}

.list-group-item-bg-1 {
  color: #171819;
  background-color: #d4d4d4;
}
.list-group-item-bg-1.list-group-item-action:hover,
.list-group-item-bg-1.list-group-item-action:focus {
  color: #171819;
  background-color: #bfbfbf;
}
.list-group-item-bg-1.list-group-item-action.active {
  color: #ffffff;
  background-color: #171819;
  border-color: #171819;
}

.list-group-item-bg-2 {
  color: #1c0e58;
  background-color: #d5d1e9;
}
.list-group-item-bg-2.list-group-item-action:hover,
.list-group-item-bg-2.list-group-item-action:focus {
  color: #1c0e58;
  background-color: #c0bcd2;
}
.list-group-item-bg-2.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c0e58;
  border-color: #1c0e58;
}

.list-group-item-bg-3 {
  color: #786ea1;
  background-color: white;
}
.list-group-item-bg-3.list-group-item-action:hover,
.list-group-item-bg-3.list-group-item-action:focus {
  color: #786ea1;
  background-color: #e6e6e6;
}
.list-group-item-bg-3.list-group-item-action.active {
  color: #ffffff;
  background-color: #786ea1;
  border-color: #786ea1;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #1e0e62;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231e0e62'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 1.5625rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #1e0e62;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 218, 197, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(30, 14, 98, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(39, 40, 42, 0.15);
  border-radius: 1.5625rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(30, 14, 98, 0.05);
  border-top-left-radius: calc(1.5625rem - 1px);
  border-top-right-radius: calc(1.5625rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(30, 14, 98, 0.2);
  border-radius: 1.875rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #272829;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
  border-bottom: 1px solid rgba(39, 40, 42, 0.3);
  border-top-left-radius: calc(1.875rem - 1px);
  border-top-right-radius: calc(1.875rem - 1px);
}
.modal-header .btn-close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 1px solid rgba(39, 40, 42, 0.3);
  border-bottom-right-radius: calc(1.875rem - 1px);
  border-bottom-left-radius: calc(1.875rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^='top'] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #1e0e62;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^='right'] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #1e0e62;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^='bottom'] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #1e0e62;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^='left'] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #1e0e62;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #1e0e62;
  border-radius: 1.5625rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(30, 14, 98, 0.2);
  border-radius: 1.875rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(30, 14, 98, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(30, 14, 98, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(30, 14, 98, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(30, 14, 98, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1e0e62;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(30, 14, 98, 0.2);
  border-top-left-radius: calc(1.875rem - 1px);
  border-top-right-radius: calc(1.875rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: rgba(39, 40, 42, 0.7);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.4s cubic-bezier(0, 0.46, 0.42, 1.11);
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.4s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.7;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 10%;
  margin-bottom: 1rem;
  margin-left: 10%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 2rem;
  height: 0.125rem;
  padding: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid transparent;
  opacity: 0.7;
  transition: all 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 10%;
  bottom: 2rem;
  left: 10%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #1e0e62;
}
.carousel-dark .carousel-caption {
  color: #1e0e62;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #272829;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
}
.offcanvas-header .btn-close {
  padding: 1rem 1rem;
  margin-top: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 2rem 2rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 320px;
  border-right: 1px solid rgba(30, 14, 98, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 320px;
  border-left: 1px solid rgba(30, 14, 98, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(30, 14, 98, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(30, 14, 98, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: '';
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #1e0e62 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #1e0e62 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

body,
p {
  letter-spacing: -0.03em;
}

.fs-1 {
  letter-spacing: -0.03em;
}

.fs-2 {
  letter-spacing: -0.03em;
}

.fs-3 {
  letter-spacing: -0.03em;
}

.fs-4 {
  letter-spacing: -0.03em;
}

.fs-5 {
  letter-spacing: -0.03em;
}

.fs-6 {
  letter-spacing: -0.03em;
}

/* Letter-spacings for heading */
.h-1,
h1,
.h1 {
  letter-spacing: -0.04em;
}

.h-2,
h2,
.h2 {
  letter-spacing: -0.04em;
}

.h-3,
h3,
.h3 {
  letter-spacing: -0.04em;
}

.h-4,
h4,
.h4 {
  letter-spacing: -0.04em;
}

.h-5,
h5,
.h5 {
  letter-spacing: -0.04em;
}

.h-6,
h6,
.h6 {
  letter-spacing: -0.04em;
}

/* Letter-spacings for display heading */
.display-1 {
  letter-spacing: -0.05em;
}

.display-2 {
  letter-spacing: -0.05em;
}

.display-3 {
  letter-spacing: -0.05em;
}

.display-4 {
  letter-spacing: -0.05em;
}

.display-5 {
  letter-spacing: -0.05em;
}

.display-6 {
  letter-spacing: -0.05em;
}

/* Color settings for some elements */
/* Fill color property for SVG icons */
.fill-action-1 {
  fill: #25dac5;
}

/* Stroke color property for SVG icons */
.stroke-action-1 {
  stroke: #25dac5;
}

/* Input/textarea placeholder colors */
.form-control.border-action-1::placeholder {
  color: #25dac5 !important;
}
.form-control.border-action-1::-moz-placeholder {
  color: #25dac5 !important;
}
.form-control.border-action-1::-webkit-input-placeholder {
  color: #25dac5 !important;
}
.form-control.border-action-1:-ms-input-placeholder {
  color: #25dac5 !important;
}

/* Arrow color for select tag */
.form-select.border-action-1 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2325dac5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-1 {
  border-color: #25dac5;
}

.form-switch .form-check-input.basic-action-1 {
  background: rgba(37, 218, 197, 0.2) !important;
}
.form-switch .form-check-input.basic-action-1 + .form-switch-pin {
  background: #25dac5 !important ;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-1:checked {
  background-color: #25dac5;
  border-color: #25dac5;
}

.form-switch .form-check-input.checked-action-1:checked {
  background: rgba(37, 218, 197, 0.2);
}
.form-switch .form-check-input.checked-action-1:checked + .form-switch-pin {
  background: #25dac5;
}

/* Fill color property for SVG icons */
.fill-action-2 {
  fill: #2f1893;
}

/* Stroke color property for SVG icons */
.stroke-action-2 {
  stroke: #2f1893;
}

/* Input/textarea placeholder colors */
.form-control.border-action-2::placeholder {
  color: #2f1893;
}
.form-control.border-action-2::-moz-placeholder {
  color: #2f1893;
}
.form-control.border-action-2::-webkit-input-placeholder {
  color: #2f1893;
}
.form-control.border-action-2:-ms-input-placeholder {
  color: #2f1893;
}

/* Arrow color for select tag */
.form-select.border-action-2 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232f1893' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-2 {
  border-color: #2f1893;
}

.form-switch .form-check-input.basic-action-2 {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.basic-action-2 + .form-switch-pin {
  background: #2f1893;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-2:checked {
  background-color: #2f1893;
  border-color: #2f1893;
}

.form-switch .form-check-input.checked-action-2:checked {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.checked-action-2:checked + .form-switch-pin {
  background: #2f1893;
}

/* Fill color property for SVG icons */
.fill-action-3 {
  fill: #2f1893;
}

/* Stroke color property for SVG icons */
.stroke-action-3 {
  stroke: #2f1893;
}

/* Input/textarea placeholder colors */
.form-control.border-action-3::placeholder {
  color: #2f1893;
}
.form-control.border-action-3::-moz-placeholder {
  color: #2f1893;
}
.form-control.border-action-3::-webkit-input-placeholder {
  color: #2f1893;
}
.form-control.border-action-3:-ms-input-placeholder {
  color: #2f1893;
}

/* Arrow color for select tag */
.form-select.border-action-3 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232f1893' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-3 {
  border-color: #2f1893;
}

.form-switch .form-check-input.basic-action-3 {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.basic-action-3 + .form-switch-pin {
  background: #2f1893;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-3:checked {
  background-color: #2f1893;
  border-color: #2f1893;
}

.form-switch .form-check-input.checked-action-3:checked {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.checked-action-3:checked + .form-switch-pin {
  background: #2f1893;
}

/* Fill color property for SVG icons */
.fill-action-4 {
  fill: #d63384;
}

/* Stroke color property for SVG icons */
.stroke-action-4 {
  stroke: #d63384;
}

/* Input/textarea placeholder colors */
.form-control.border-action-4::placeholder {
  color: #d63384;
}
.form-control.border-action-4::-moz-placeholder {
  color: #d63384;
}
.form-control.border-action-4::-webkit-input-placeholder {
  color: #d63384;
}
.form-control.border-action-4:-ms-input-placeholder {
  color: #d63384;
}

/* Arrow color for select tag */
.form-select.border-action-4 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23D63384' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-4 {
  border-color: #d63384;
}

.form-switch .form-check-input.basic-action-4 {
  background: rgba(214, 51, 132, 0.2);
}
.form-switch .form-check-input.basic-action-4 + .form-switch-pin {
  background: #d63384;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-4:checked {
  background-color: #d63384;
  border-color: #d63384;
}

.form-switch .form-check-input.checked-action-4:checked {
  background: rgba(214, 51, 132, 0.2);
}
.form-switch .form-check-input.checked-action-4:checked + .form-switch-pin {
  background: #d63384;
}

/* Fill color property for SVG icons */
.fill-action-5 {
  fill: #dc3545;
}

/* Stroke color property for SVG icons */
.stroke-action-5 {
  stroke: #dc3545;
}

/* Input/textarea placeholder colors */
.form-control.border-action-5::placeholder {
  color: #dc3545;
}
.form-control.border-action-5::-moz-placeholder {
  color: #dc3545;
}
.form-control.border-action-5::-webkit-input-placeholder {
  color: #dc3545;
}
.form-control.border-action-5:-ms-input-placeholder {
  color: #dc3545;
}

/* Arrow color for select tag */
.form-select.border-action-5 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23DC3545' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-5 {
  border-color: #dc3545;
}

.form-switch .form-check-input.basic-action-5 {
  background: rgba(220, 53, 69, 0.2);
}
.form-switch .form-check-input.basic-action-5 + .form-switch-pin {
  background: #dc3545;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-5:checked {
  background-color: #dc3545;
  border-color: #dc3545;
}

.form-switch .form-check-input.checked-action-5:checked {
  background: rgba(220, 53, 69, 0.2);
}
.form-switch .form-check-input.checked-action-5:checked + .form-switch-pin {
  background: #dc3545;
}

/* Fill color property for SVG icons */
.fill-action-6 {
  fill: #fd7e14;
}

/* Stroke color property for SVG icons */
.stroke-action-6 {
  stroke: #fd7e14;
}

/* Input/textarea placeholder colors */
.form-control.border-action-6::placeholder {
  color: #fd7e14;
}
.form-control.border-action-6::-moz-placeholder {
  color: #fd7e14;
}
.form-control.border-action-6::-webkit-input-placeholder {
  color: #fd7e14;
}
.form-control.border-action-6:-ms-input-placeholder {
  color: #fd7e14;
}

/* Arrow color for select tag */
.form-select.border-action-6 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FD7E14' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-6 {
  border-color: #fd7e14;
}

.form-switch .form-check-input.basic-action-6 {
  background: rgba(253, 126, 20, 0.2);
}
.form-switch .form-check-input.basic-action-6 + .form-switch-pin {
  background: #fd7e14;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-6:checked {
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.form-switch .form-check-input.checked-action-6:checked {
  background: rgba(253, 126, 20, 0.2);
}
.form-switch .form-check-input.checked-action-6:checked + .form-switch-pin {
  background: #fd7e14;
}

/* Fill color property for SVG icons */
.fill-action-7 {
  fill: #e93a7d;
}

/* Stroke color property for SVG icons */
.stroke-action-7 {
  stroke: #e93a7d;
}

/* Input/textarea placeholder colors */
.form-control.border-action-7::placeholder {
  color: #e93a7d;
}
.form-control.border-action-7::-moz-placeholder {
  color: #e93a7d;
}
.form-control.border-action-7::-webkit-input-placeholder {
  color: #e93a7d;
}
.form-control.border-action-7:-ms-input-placeholder {
  color: #e93a7d;
}

/* Arrow color for select tag */
.form-select.border-action-7 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e93a7d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-7 {
  border-color: #e93a7d;
}

.form-switch .form-check-input.basic-action-7 {
  background: rgba(233, 58, 125, 0.2);
}
.form-switch .form-check-input.basic-action-7 + .form-switch-pin {
  background: #e93a7d;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-7:checked {
  background-color: #e93a7d;
  border-color: #e93a7d;
}

.form-switch .form-check-input.checked-action-7:checked {
  background: rgba(233, 58, 125, 0.2);
}
.form-switch .form-check-input.checked-action-7:checked + .form-switch-pin {
  background: #e93a7d;
}

/* Fill color property for SVG icons */
.fill-action-8 {
  fill: #8bd74e;
}

/* Stroke color property for SVG icons */
.stroke-action-8 {
  stroke: #8bd74e;
}

/* Input/textarea placeholder colors */
.form-control.border-action-8::placeholder {
  color: #8bd74e;
}
.form-control.border-action-8::-moz-placeholder {
  color: #8bd74e;
}
.form-control.border-action-8::-webkit-input-placeholder {
  color: #8bd74e;
}
.form-control.border-action-8:-ms-input-placeholder {
  color: #8bd74e;
}

/* Arrow color for select tag */
.form-select.border-action-8 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238BD74E' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-action-8 {
  border-color: #8bd74e;
}

.form-switch .form-check-input.basic-action-8 {
  background: rgba(139, 215, 78, 0.2);
}
.form-switch .form-check-input.basic-action-8 + .form-switch-pin {
  background: #8bd74e;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-action-8:checked {
  background-color: #8bd74e;
  border-color: #8bd74e;
}

.form-switch .form-check-input.checked-action-8:checked {
  background: rgba(139, 215, 78, 0.2);
}
.form-switch .form-check-input.checked-action-8:checked + .form-switch-pin {
  background: #8bd74e;
}

/* Fill color property for SVG icons */
.fill-dark-1 {
  fill: #1e0e62;
}

/* Stroke color property for SVG icons */
.stroke-dark-1 {
  stroke: #1e0e62;
}

/* Input/textarea placeholder colors */
.form-control.border-dark-1::placeholder {
  color: #1e0e62;
}
.form-control.border-dark-1::-moz-placeholder {
  color: #1e0e62;
}
.form-control.border-dark-1::-webkit-input-placeholder {
  color: #1e0e62;
}
.form-control.border-dark-1:-ms-input-placeholder {
  color: #1e0e62;
}

/* Arrow color for select tag */
.form-select.border-dark-1 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231e0e62' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-dark-1 {
  border-color: #1e0e62;
}

.form-switch .form-check-input.basic-dark-1 {
  background: rgba(30, 14, 98, 0.2);
}
.form-switch .form-check-input.basic-dark-1 + .form-switch-pin {
  background: #1e0e62;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-dark-1:checked {
  background-color: #1e0e62;
  border-color: #1e0e62;
}

.form-switch .form-check-input.checked-dark-1:checked {
  background: rgba(30, 14, 98, 0.2);
}
.form-switch .form-check-input.checked-dark-1:checked + .form-switch-pin {
  background: #1e0e62;
}

/* Fill color property for SVG icons */
.fill-dark-2 {
  fill: rgba(39, 40, 42, 0.7);
}

/* Stroke color property for SVG icons */
.stroke-dark-2 {
  stroke: rgba(39, 40, 42, 0.7);
}

/* Input/textarea placeholder colors */
.form-control.border-dark-2::placeholder {
  color: rgba(39, 40, 42, 0.7);
}
.form-control.border-dark-2::-moz-placeholder {
  color: rgba(39, 40, 42, 0.7);
}
.form-control.border-dark-2::-webkit-input-placeholder {
  color: rgba(39, 40, 42, 0.7);
}
.form-control.border-dark-2:-ms-input-placeholder {
  color: rgba(39, 40, 42, 0.7);
}

/* Arrow color for select tag */
.form-select.border-dark-2 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(39, 40, 42, 0.7)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-dark-2 {
  border-color: rgba(39, 40, 42, 0.7);
}

.form-switch .form-check-input.basic-dark-2 {
  background: rgba(39, 40, 42, 0.2);
}
.form-switch .form-check-input.basic-dark-2 + .form-switch-pin {
  background: rgba(39, 40, 42, 0.7);
}

/* "Checked" color for checkboxes, radios and switches */
.checked-dark-2:checked {
  background-color: rgba(39, 40, 42, 0.7);
  border-color: rgba(39, 40, 42, 0.7);
}

.form-switch .form-check-input.checked-dark-2:checked {
  background: rgba(39, 40, 42, 0.2);
}
.form-switch .form-check-input.checked-dark-2:checked + .form-switch-pin {
  background: rgba(39, 40, 42, 0.7);
}

/* Fill color property for SVG icons */
.fill-dark-3 {
  fill: rgba(39, 40, 42, 0.3);
}

/* Stroke color property for SVG icons */
.stroke-dark-3 {
  stroke: rgba(39, 40, 42, 0.3);
}

/* Input/textarea placeholder colors */
.form-control.border-dark-3::placeholder {
  color: rgba(39, 40, 42, 0.3);
}
.form-control.border-dark-3::-moz-placeholder {
  color: rgba(39, 40, 42, 0.3);
}
.form-control.border-dark-3::-webkit-input-placeholder {
  color: rgba(39, 40, 42, 0.3);
}
.form-control.border-dark-3:-ms-input-placeholder {
  color: rgba(39, 40, 42, 0.3);
}

/* Arrow color for select tag */
.form-select.border-dark-3 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(39, 40, 42, 0.3)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-dark-3 {
  border-color: rgba(39, 40, 42, 0.3);
}

.form-switch .form-check-input.basic-dark-3 {
  background: rgba(39, 40, 42, 0.2);
}
.form-switch .form-check-input.basic-dark-3 + .form-switch-pin {
  background: rgba(39, 40, 42, 0.3);
}

/* "Checked" color for checkboxes, radios and switches */
.checked-dark-3:checked {
  background-color: rgba(39, 40, 42, 0.3);
  border-color: rgba(39, 40, 42, 0.3);
}

.form-switch .form-check-input.checked-dark-3:checked {
  background: rgba(39, 40, 42, 0.2);
}
.form-switch .form-check-input.checked-dark-3:checked + .form-switch-pin {
  background: rgba(39, 40, 42, 0.3);
}

/* Fill color property for SVG icons */
.fill-light-1 {
  fill: #ffffff;
}

/* Stroke color property for SVG icons */
.stroke-light-1 {
  stroke: #ffffff;
}

/* Input/textarea placeholder colors */
.form-control.border-light-1::placeholder {
  color: #ffffff;
}
.form-control.border-light-1::-moz-placeholder {
  color: #ffffff;
}
.form-control.border-light-1::-webkit-input-placeholder {
  color: #ffffff;
}
.form-control.border-light-1:-ms-input-placeholder {
  color: #ffffff;
}

/* Arrow color for select tag */
.form-select.border-light-1 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-light-1 {
  border-color: #ffffff;
}

.form-switch .form-check-input.basic-light-1 {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.basic-light-1 + .form-switch-pin {
  background: #ffffff;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-light-1:checked {
  background-color: #ffffff;
  border-color: #ffffff;
}

.form-switch .form-check-input.checked-light-1:checked {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.checked-light-1:checked + .form-switch-pin {
  background: #ffffff;
}

/* Fill color property for SVG icons */
.fill-light-2 {
  fill: rgba(255, 255, 255, 0.7);
}

/* Stroke color property for SVG icons */
.stroke-light-2 {
  stroke: rgba(255, 255, 255, 0.7);
}

/* Input/textarea placeholder colors */
.form-control.border-light-2::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control.border-light-2::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control.border-light-2::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form-control.border-light-2:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* Arrow color for select tag */
.form-select.border-light-2 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(255, 255, 255, 0.7)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-light-2 {
  border-color: rgba(255, 255, 255, 0.7);
}

.form-switch .form-check-input.basic-light-2 {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.basic-light-2 + .form-switch-pin {
  background: rgba(255, 255, 255, 0.7);
}

/* "Checked" color for checkboxes, radios and switches */
.checked-light-2:checked {
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

.form-switch .form-check-input.checked-light-2:checked {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.checked-light-2:checked + .form-switch-pin {
  background: rgba(255, 255, 255, 0.7);
}

/* Fill color property for SVG icons */
.fill-light-3 {
  fill: rgba(255, 255, 255, 0.45);
}

/* Stroke color property for SVG icons */
.stroke-light-3 {
  stroke: rgba(255, 255, 255, 0.45);
}

/* Input/textarea placeholder colors */
.form-control.border-light-3::placeholder {
  color: rgba(255, 255, 255, 0.45);
}
.form-control.border-light-3::-moz-placeholder {
  color: rgba(255, 255, 255, 0.45);
}
.form-control.border-light-3::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.45);
}
.form-control.border-light-3:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.45);
}

/* Arrow color for select tag */
.form-select.border-light-3 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(255, 255, 255, 0.45)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-light-3 {
  border-color: rgba(255, 255, 255, 0.45);
}

.form-switch .form-check-input.basic-light-3 {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.basic-light-3 + .form-switch-pin {
  background: rgba(255, 255, 255, 0.45);
}

/* "Checked" color for checkboxes, radios and switches */
.checked-light-3:checked {
  background-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(255, 255, 255, 0.45);
}

.form-switch .form-check-input.checked-light-3:checked {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.checked-light-3:checked + .form-switch-pin {
  background: rgba(255, 255, 255, 0.45);
}

/* Fill color property for SVG icons */
.fill-bg-1 {
  fill: #272829;
}

/* Stroke color property for SVG icons */
.stroke-bg-1 {
  stroke: #272829;
}

/* Input/textarea placeholder colors */
.form-control.border-bg-1::placeholder {
  color: #272829;
}
.form-control.border-bg-1::-moz-placeholder {
  color: #272829;
}
.form-control.border-bg-1::-webkit-input-placeholder {
  color: #272829;
}
.form-control.border-bg-1:-ms-input-placeholder {
  color: #272829;
}

/* Arrow color for select tag */
.form-select.border-bg-1 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23272829' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-bg-1 {
  border-color: #272829;
}

.form-switch .form-check-input.basic-bg-1 {
  background: rgba(39, 40, 41, 0.2);
}
.form-switch .form-check-input.basic-bg-1 + .form-switch-pin {
  background: #272829;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-bg-1:checked {
  background-color: #272829;
  border-color: #272829;
}

.form-switch .form-check-input.checked-bg-1:checked {
  background: rgba(39, 40, 41, 0.2);
}
.form-switch .form-check-input.checked-bg-1:checked + .form-switch-pin {
  background: #272829;
}

/* Fill color property for SVG icons */
.fill-bg-2 {
  fill: #2f1893;
}

/* Stroke color property for SVG icons */
.stroke-bg-2 {
  stroke: #2f1893;
}

/* Input/textarea placeholder colors */
.form-control.border-bg-2::placeholder {
  color: #2f1893;
}
.form-control.border-bg-2::-moz-placeholder {
  color: #2f1893;
}
.form-control.border-bg-2::-webkit-input-placeholder {
  color: #2f1893;
}
.form-control.border-bg-2:-ms-input-placeholder {
  color: #2f1893;
}

/* Arrow color for select tag */
.form-select.border-bg-2 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232f1893' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-bg-2 {
  border-color: #2f1893;
}

.form-switch .form-check-input.basic-bg-2 {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.basic-bg-2 + .form-switch-pin {
  background: #2f1893;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-bg-2:checked {
  background-color: #2f1893;
  border-color: #2f1893;
}

.form-switch .form-check-input.checked-bg-2:checked {
  background: rgba(47, 24, 147, 0.2);
}
.form-switch .form-check-input.checked-bg-2:checked + .form-switch-pin {
  background: #2f1893;
}

/* Fill color property for SVG icons */
.fill-bg-3 {
  fill: #ffffff;
}

/* Stroke color property for SVG icons */
.stroke-bg-3 {
  stroke: #ffffff;
}

/* Input/textarea placeholder colors */
.form-control.border-bg-3::placeholder {
  color: #ffffff;
}
.form-control.border-bg-3::-moz-placeholder {
  color: #ffffff;
}
.form-control.border-bg-3::-webkit-input-placeholder {
  color: #ffffff;
}
.form-control.border-bg-3:-ms-input-placeholder {
  color: #ffffff;
}

/* Arrow color for select tag */
.form-select.border-bg-3 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* "Basic" color for checkboxes, radios and switches */
.basic-bg-3 {
  border-color: #ffffff;
}

.form-switch .form-check-input.basic-bg-3 {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.basic-bg-3 + .form-switch-pin {
  background: #ffffff;
}

/* "Checked" color for checkboxes, radios and switches */
.checked-bg-3:checked {
  background-color: #ffffff;
  border-color: #ffffff;
}

.form-switch .form-check-input.checked-bg-3:checked {
  background: rgba(255, 255, 255, 0.2);
}
.form-switch .form-check-input.checked-bg-3:checked + .form-switch-pin {
  background: #ffffff;
}

/* Text highlight color */
::-moz-selection {
  background: #e93a7d;
  color: #ffffff;
}

::-webkit-selection {
  background: #e93a7d;
  color: #ffffff;
}

::selection {
  background: #e93a7d;
  color: #ffffff;
}

/* Set default font-weight */
.font-body,
.font-monospace {
  font-weight: 400;
}

.font-heading {
  font-weight: 700;
}

/* Display all links as inline-block */
a {
  display: inline-block;
}

/* Button with width=height. Used for a Play button */
.btn-width-equal-height {
  padding: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-width-equal-height.btn-sm,
.btn-group-sm > .btn-width-equal-height.btn {
  width: 2.5rem;
  height: 2.5rem;
}
.btn-width-equal-height.btn-lg,
.btn-group-lg > .btn-width-equal-height.btn {
  width: 3.75rem;
  height: 3.75rem;
}

/* Custom light color for button for dark block */
.btn-light-custom {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.btn-light-custom:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Responsive text (the <br> tag will be removed on the specific breakpoint) */
@media (max-width: 575.98px) {
  .remove-br br {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .remove-br-sm br {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .remove-br-md br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .remove-br-lg br {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .remove-br-xl br {
    display: none;
  }
}
/* Mobile navigation styles */
.custom-mobile-nav-btn {
  background: #3d3f41;
}
.custom-mobile-nav-btn:hover {
  background: #65686a;
}

.custom-dark-offcanvas-bg {
  background: #3d3f41;
}

/* Dropdowns styles */
.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(39, 40, 42, 0.15);
}

.dropdown-divider {
  background-color: rgba(39, 40, 42, 0.3);
}

.dropdown-item-text {
  color: rgba(39, 40, 42, 0.7);
}

/* Input, Textarea, Select styles */
.form-control:focus::placeholder {
  color: transparent !important;
}
.form-control:focus::-moz-placeholder {
  color: transparent !important;
}
.form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}

/* Fix wrong margin-left */
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback).border-start-0 {
  margin-left: 0;
}

/* Light transparent background for light-bordered controls */
.border-light-1.input-group-text,
.border-light-1.form-control,
.border-light-1.form-select,
.border-light-2.input-group-text,
.border-light-2.form-control,
.border-light-2.form-select,
.border-light-3.input-group-text,
.border-light-3.form-control,
.border-light-3.form-select,
.border-bg-2.input-group-text,
.border-bg-2.form-control,
.border-bg-2.form-select,
.border-bg-3.input-group-text,
.border-bg-3.form-control,
.border-bg-3.form-select,
.text-light-1.input-group-text,
.text-light-1.form-control,
.text-light-1.form-select,
.text-light-2.input-group-text,
.text-light-2.form-control,
.text-light-2.form-select,
.text-light-3.input-group-text,
.text-light-3.form-control,
.text-light-3.form-select,
.text-bg-2.input-group-text,
.text-bg-2.form-control,
.text-bg-2.form-select,
.text-bg-3.input-group-text,
.text-bg-3.form-control,
.text-bg-3.form-select {
  background-color: rgba(255, 255, 255, 0.1);
}
.border-light-1.form-select option,
.border-light-2.form-select option,
.border-light-3.form-select option,
.border-bg-2.form-select option,
.border-bg-3.form-select option,
.text-light-1.form-select option,
.text-light-2.form-select option,
.text-light-3.form-select option,
.text-bg-2.form-select option,
.text-bg-3.form-select option {
  color: #ffffff;
  background: #272829;
}

/* Checkboxes / radios styles */
.form-check-input {
  margin-right: 0.5rem;
}
.form-check-input.form-control-sm {
  width: 1rem;
  height: 1rem;
  min-height: 1rem;
  padding: 0;
  margin-top: 0.25rem;
}
.form-check-input.form-control-lg {
  width: 2rem;
  height: 2rem;
  min-height: 2rem;
  padding: 0;
}

/* Switches styles */
.form-switch .form-check-input {
  opacity: 0;
  z-index: -1;
  position: absolute;
}
.form-switch .form-check-input:checked + .form-switch-wrapper {
  background: rgba(37, 218, 197, 0.2);
}
.form-switch .form-check-input:checked + .form-switch-wrapper .form-switch-pin {
  left: 100%;
  transform: translateX(-0.875rem);
  background: #25dac5;
}
.form-switch
  .form-check-input:disabled
  + .form-switch-wrapper
  .form-switch-pin {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-switch
  .form-check-input.form-control-sm:checked
  + .form-switch-wrapper
  .form-switch-pin {
  transform: translateX(-0.75rem);
}
.form-switch .form-check-input.form-control-sm + .form-switch-wrapper {
  width: 1.5rem;
  height: 0.75rem;
  min-height: 0.75rem;
  margin-top: 0;
}
.form-switch
  .form-check-input.form-control-sm
  + .form-switch-wrapper
  .form-switch-pin {
  width: 1rem;
  height: 1rem;
}
.form-switch
  .form-check-input.form-control-lg:checked
  + .form-switch-wrapper
  .form-switch-pin {
  transform: translateX(-1rem);
}
.form-switch .form-check-input.form-control-lg + .form-switch-wrapper {
  width: 2.5rem;
  height: 1rem;
  min-height: 1rem;
  margin-top: 0;
}
.form-switch
  .form-check-input.form-control-lg
  + .form-switch-wrapper
  .form-switch-pin {
  width: 1.25rem;
  height: 1.25rem;
}

.form-switch-wrapper {
  margin-left: 0.125rem;
  margin-right: 0.5rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 0.875rem;
  border-radius: 25rem;
  background: rgba(30, 14, 98, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.form-switch-pin {
  position: absolute;
  top: -0.125rem;
  left: 0;
  transform: translateX(-0.125rem);
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  background: #1e0e62;
  transition: all 0.2s ease-in-out;
}

/* Carousel (Slider) styles */
.carousel-indicators [data-bs-target]:hover {
  transform: scaleY(2);
}

.carousel-indicators.carousel-indicators-circles [data-bs-target] {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-radius: 100%;
}
.carousel-indicators.carousel-indicators-circles [data-bs-target]:hover {
  transform: scale(1.2);
}

.carousel-indicators.carousel-indicators-dots [data-bs-target] {
  width: 0.3333333333rem;
  height: 0.3333333333rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-top: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid transparent;
  border-radius: 100%;
}
.carousel-indicators.carousel-indicators-dots [data-bs-target]:hover {
  transform: scale(1.5);
}
.carousel-indicators.carousel-indicators-dots .active {
  transform: scale(2);
}

.carousel-arrows-circled .carousel-control-prev,
.carousel-arrows-circled .carousel-control-next {
  opacity: 1;
}
.carousel-arrows-circled
  .carousel-control-next:hover
  .carousel-control-next-icon {
  transform: scale(1.1);
}
.carousel-arrows-circled
  .carousel-control-prev:hover
  .carousel-control-prev-icon {
  transform: scale(1.1);
}
.carousel-arrows-circled .carousel-control-prev-icon,
.carousel-arrows-circled .carousel-control-next-icon {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-size: 2rem 2rem;
  background-color: #1e0e62;
  border-radius: 100%;
  transition: all 0.15s ease;
}
.carousel-arrows-circled .carousel-control-prev-icon {
  background-position: calc(50% - 0.125rem) 50%;
}
.carousel-arrows-circled .carousel-control-next-icon {
  background-position: calc(50% + 0.125rem) 50%;
}
.carousel-arrows-circled.carousel-dark .carousel-control-prev-icon,
.carousel-arrows-circled.carousel-dark .carousel-control-next-icon {
  background-color: #000000;
}

/* Put your custom styles here */
/* End of your custom styles */
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.link-action-1 {
  color: #25dac5;
}
.link-action-1:hover,
.link-action-1:focus {
  color: #51e1d1;
}

.link-action-2 {
  color: #2f1893;
}
.link-action-2:hover,
.link-action-2:focus {
  color: #261376;
}

.link-action-3 {
  color: #2f1893;
}
.link-action-3:hover,
.link-action-3:focus {
  color: #261376;
}

.link-action-4 {
  color: #d63384;
}
.link-action-4:hover,
.link-action-4:focus {
  color: #ab296a;
}

.link-action-5 {
  color: #dc3545;
}
.link-action-5:hover,
.link-action-5:focus {
  color: #b02a37;
}

.link-action-6 {
  color: #fd7e14;
}
.link-action-6:hover,
.link-action-6:focus {
  color: #fd9843;
}

.link-action-7 {
  color: #e93a7d;
}
.link-action-7:hover,
.link-action-7:focus {
  color: #ba2e64;
}

.link-action-8 {
  color: #8bd74e;
}
.link-action-8:hover,
.link-action-8:focus {
  color: #a2df71;
}

.link-dark-1 {
  color: #1e0e62;
}
.link-dark-1:hover,
.link-dark-1:focus {
  color: #180b4e;
}

.link-dark-2 {
  color: rgba(39, 40, 42, 0.7);
}
.link-dark-2:hover,
.link-dark-2:focus {
  color: rgba(27, 27, 29, 0.76);
}

.link-dark-3 {
  color: rgba(39, 40, 42, 0.3);
}
.link-dark-3:hover,
.link-dark-3:focus {
  color: rgba(16, 17, 17, 0.44);
}

.link-light-1 {
  color: #ffffff;
}
.link-light-1:hover,
.link-light-1:focus {
  color: white;
}

.link-light-2 {
  color: rgba(255, 255, 255, 0.7);
}
.link-light-2:hover,
.link-light-2:focus {
  color: rgba(255, 255, 255, 0.76);
}

.link-light-3 {
  color: rgba(255, 255, 255, 0.45);
}
.link-light-3:hover,
.link-light-3:focus {
  color: rgba(255, 255, 255, 0.56);
}

.link-bg-1 {
  color: #272829;
}
.link-bg-1:hover,
.link-bg-1:focus {
  color: #1f2021;
}

.link-bg-2 {
  color: #2f1893;
}
.link-bg-2:hover,
.link-bg-2:focus {
  color: #261376;
}

.link-bg-3 {
  color: #ffffff;
}
.link-bg-3:hover,
.link-bg-3:focus {
  color: white;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: '';
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.4 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(39, 40, 42, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(39, 40, 42, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(39, 40, 42, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid rgba(39, 40, 42, 0.3) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(39, 40, 42, 0.3) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid rgba(39, 40, 42, 0.3) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(39, 40, 42, 0.3) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid rgba(39, 40, 42, 0.3) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-action-1 {
  border-color: #25dac5 !important;
}

.border-action-2 {
  border-color: #2f1893 !important;
}

.border-action-3 {
  border-color: #2f1893 !important;
}

.border-action-4 {
  border-color: #d63384 !important;
}

.border-action-5 {
  border-color: #dc3545 !important;
}

.border-action-6 {
  border-color: #fd7e14 !important;
}

.border-action-7 {
  border-color: #e93a7d !important;
}

.border-action-8 {
  border-color: #8bd74e !important;
}

.border-dark-1 {
  border-color: #1e0e62 !important;
}

.border-dark-2 {
  border-color: rgba(39, 40, 42, 0.7) !important;
}

.border-dark-3 {
  border-color: rgba(39, 40, 42, 0.3) !important;
}

.border-light-1 {
  border-color: #ffffff !important;
}

.border-light-2 {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-light-3 {
  border-color: rgba(255, 255, 255, 0.45) !important;
}

.border-bg-1 {
  border-color: #272829 !important;
}

.border-bg-2 {
  border-color: #2f1893 !important;
}

.border-bg-3 {
  border-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.font-body {
  font-family: 'Baloo 2', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.font-heading {
  font-family: 'Baloo 2', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.fs-1 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-2 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-3 {
  font-size: 1.25rem !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 0.875rem !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-1 {
  font-weight: 100 !important;
}

.fw-2 {
  font-weight: 200 !important;
}

.fw-3 {
  font-weight: 300 !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.fw-7 {
  font-weight: 700 !important;
}

.fw-8 {
  font-weight: 800 !important;
}

.fw-9 {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-action-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-1-rgb), var(--bs-text-opacity)) !important;
}

.text-action-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-2-rgb), var(--bs-text-opacity)) !important;
}

.text-action-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-3-rgb), var(--bs-text-opacity)) !important;
}

.text-action-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-4-rgb), var(--bs-text-opacity)) !important;
}

.text-action-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-5-rgb), var(--bs-text-opacity)) !important;
}

.text-action-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-6-rgb), var(--bs-text-opacity)) !important;
}

.text-action-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-7-rgb), var(--bs-text-opacity)) !important;
}

.text-action-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-action-8-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-1-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-2-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-3-rgb), var(--bs-text-opacity)) !important;
}

.text-light-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-1-rgb), var(--bs-text-opacity)) !important;
}

.text-light-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-2-rgb), var(--bs-text-opacity)) !important;
}

.text-light-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-3-rgb), var(--bs-text-opacity)) !important;
}

.text-bg-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bg-1-rgb), var(--bs-text-opacity)) !important;
}

.text-bg-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bg-2-rgb), var(--bs-text-opacity)) !important;
}

.text-bg-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bg-3-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(30, 14, 98, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-action-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-1-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-2-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-3-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-4-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-5-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-6-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-7-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-action-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-action-8-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-dark-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-1-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-light-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-2-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-light-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-3-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-bg-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bg-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-bg-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bg-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-bg-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bg-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 1.5625rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 1.25rem !important;
}

.rounded-2 {
  border-radius: 1.5625rem !important;
}

.rounded-3 {
  border-radius: 1.875rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 1.5625rem !important;
  border-top-right-radius: 1.5625rem !important;
}

.rounded-end {
  border-top-right-radius: 1.5625rem !important;
  border-bottom-right-radius: 1.5625rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 1.5625rem !important;
  border-bottom-left-radius: 1.5625rem !important;
}

.rounded-start {
  border-bottom-left-radius: 1.5625rem !important;
  border-top-left-radius: 1.5625rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.zi--1 {
  z-index: -1 !important;
}

.zi-0 {
  z-index: 0 !important;
}

.zi-1 {
  z-index: 1 !important;
}

.zi-2 {
  z-index: 2 !important;
}

.zi-3 {
  z-index: 3 !important;
}

.zi-4 {
  z-index: 4 !important;
}

.zi-5 {
  z-index: 5 !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-size-contain {
  background-size: contain !important;
}

.bg-size-fit-x {
  background-size: 100% auto !important;
}

.bg-size-fit-y {
  background-size: auto 100% !important;
}

.bg-position-top-left {
  background-position: 0 0 !important;
}

.bg-position-top-center {
  background-position: 50% 0 !important;
}

.bg-position-top-right {
  background-position: 100% 0 !important;
}

.bg-position-center {
  background-position: 50% 50% !important;
}

.bg-position-bottom-left {
  background-position: 0 100% !important;
}

.bg-position-bottom-center {
  background-position: 50% 100% !important;
}

.bg-position-bottom-right {
  background-position: 100% 100% !important;
}

.bg-fixed {
  background-attachment: fixed !important;
}

.bg-repeat-none {
  background-repeat: no-repeat !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.5rem !important;
  }

  .fs-2 {
    font-size: 1.375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.form1para {
  font-size: 0.97rem;
  color: gray;
}

.inpText {
  font-size: 3px !important ;
}

.inpContainer {
  border-radius: 20rem !important;
}
